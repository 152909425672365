import React, { FC } from 'react'
import PdpHeroWrapper from '../single-component-picker/components/pdp-hero-wrapper'
import { SingleComponentPickerItem } from '../single-component-picker'
import ContentfulHero from '../single-component-picker/components/renderers/ContentfulHero'
import { ReferenceType } from '@/schemas/ReferenceType'
import { useData } from '@/hooks'
import { DataSourceType } from '@/hooks/useData'

interface ComponentHeroPickerProps {
  data: ReferenceType
}

export const ComponentHeroPicker: FC<ComponentHeroPickerProps> = ({ data }) => {
  const reader = useData<
    | Pick<SingleComponentPickerItem & { _type: 'pdpHero' }, '_id' | '_type'>
    | Pick<SingleComponentPickerItem & { _type: 'hero' }, '_id' | '_type'>
  >(DataSourceType.CONTENTFUL, '*[_id == $id][0]{_id,_type}', {
    id: data._ref,
  })
  const section = reader()

  if (!section) {
    return null
  }

  switch (section._type) {
    case 'hero': {
      return <ContentfulHero heroData={{ _type: 'hero', _id: section._id }} />
    }
    case 'pdpHero': {
      return <PdpHeroWrapper cmsData={{ _type: 'pdpHero', _id: section._id }} />
    }

    default:
      return null
  }
}
