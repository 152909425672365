import React, { FC, useMemo, useCallback, useEffect } from 'react'
import Head from 'next/head'
import { useThemeUI, get } from 'theme-ui'
import { ComponentPicker } from '@/components'
import { ComponentHeroPicker } from '@/components/HeroPicker'
import { ThemeProvider } from '@heights/heights-context'
import { Button } from '@heights/heights-ui-library'
import { Theme } from '@/constants'
import { landingPage, product } from '@/schemas'
import { Layout, LayoutProps } from './Layout'
import { RichTextContent } from '@/components/RichTextContent'
import { useProduct } from '@/hooks'
import { DataSourceType, useData } from '@/hooks/useData'
import { customStorefrontAttributesSignal } from '@/hooks/useStorefrontCart'
import { localStorage } from '@/utils/localStorage'
import { useCurrency } from '@heights/heights-react-hooks'
import { decodeShopifyId } from '@/utils/shopify'

export type LandingPageProps = {
  preview?: boolean
  page: typeof landingPage.landingPage
}

const isAlwaysPreview = process.env.NEXT_PUBLIC_ALWAYS_PREVIEW == '1'

const [query, type] = product.builder.filter('_id == $id').first().use()

export const LandingPage: FC<LandingPageProps> = ({ preview, page }) => {
  const { theme } = useThemeUI()
  const { currencyCode } = useCurrency()
  const reader = useData<typeof type>(DataSourceType.CONTENTFUL, query, {
    id: page.fields?.pageProduct?._ref,
  })

  const pageProduct = reader()

  const mergedRawTheme = useMemo(() => {
    if (page?.fields?.theme === Theme.V2) {
      return {
        ...theme,
        colors: {
          ...theme.colors,
          primary: get(theme, 'colors.blue.900'),
        },
        styles: {
          h1: { ...theme.styles?.h1, fontFamily: "'Playfair Display', serif" },
          h2: { ...theme.styles?.h2, fontFamily: "'Playfair Display', serif" },
          h3: { ...theme.styles?.h3, fontFamily: "'Playfair Display', serif" },
        },
      } as typeof theme
    }
    return theme
  }, [theme, page])

  const themeValue = useMemo(() => {
    if (
      page?.fields?.theme === Theme.TEAL ||
      page?.fields?.theme === Theme.GREEN ||
      page?.fields?.theme === Theme.WHITE
    ) {
      return page.fields.theme as Theme.TEAL | Theme.GREEN | Theme.WHITE
    }
    return 'default' as const
  }, [page?.fields?.theme])

  const handleExitPreview = useCallback(() => {
    window.location.assign('/api/exit-preview')
  }, [])

  const product = useProduct(pageProduct?.fields?.productId)

  const productSchema = product
    ? {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: product.name,
        image: product.productImages?.map((image) => image.url),
        sku: decodeShopifyId(product.shopifyProductId ?? product.productId),
        brand: {
          '@type': 'Brand',
          name: 'Heights',
        },
        offers: product.variants.map((variant) => ({
          '@type': 'Offer',
          name: variant.highlightLabel,
          sku: decodeShopifyId(variant.variantId),
          price: variant.costPerChargeFrequency[currencyCode],
          priceCurrency: currencyCode,
          availability: 'InStock',
        })),
      }
    : null

  const sharedPageContent = (
    <>
      {page?.fields?.hero && <ComponentHeroPicker data={page.fields.hero} />}
      <ComponentPicker data={page?.fields?.sections ?? []} />
    </>
  )

  useEffect(() => {
    const trackingId =
      page.fields?.trackingBreadcrumbId ??
      localStorage.getItem('_page_tracking_breadcrumb_id')
    if (trackingId) {
      localStorage.setItem('_page_tracking_breadcrumb_id', trackingId)
      customStorefrontAttributesSignal.value = {
        ...customStorefrontAttributesSignal.value,
        _page_tracking_breadcrumb_id: trackingId,
      }
    }
  }, [page])

  return (
    <ThemeProvider rawTheme={mergedRawTheme} theme={themeValue}>
      <Layout
        transparentNav={page.fields?.transparentNav}
        isEmbeddedPage={page.fields?.isEmbeddedPage}
        isProductPage={page.fields?.isPDP}
        hideAnnouncementBanner={page.fields?.hideAnnouncementBanner}
        navTheme={page.fields?.navTheme as LayoutProps['navTheme']}
        navMode={page.fields?.navMode as LayoutProps['navMode']}
        miniBannerContent={
          page.fields?.miniBannerContent ? (
            <RichTextContent
              inheritParagraphFont
              document={page.fields.miniBannerContent}
            />
          ) : null
        }
        pageProduct={product ?? null}
        seo={{
          title: page.fields?.title,
          robots: page.fields?.hideFromSearchEngines ? 'noindex nofollow' : '',
          description: page.fields?.metaDescription,
        }}
      >
        <Head>
          <title>{page?.fields?.title}</title>
          {!!productSchema && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(productSchema, null, 2),
              }}
            />
          )}
        </Head>
        <div>
          {preview && (
            <Button
              variant="unstyled"
              disabled={isAlwaysPreview}
              className="fixed top-4 left-4 z-50 px-4 py-2 bg-primary text-2 rounded-full border border-bright text-bright"
              onClick={handleExitPreview}
            >
              {isAlwaysPreview ? 'Preview mode' : 'Exit preview'}
            </Button>
          )}
          {sharedPageContent}
        </div>
      </Layout>
    </ThemeProvider>
  )
}
